<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">Cotizaciones</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
</style>